import React from 'react';
import {appConfig} from "../appconfig";
import {withRouter} from "react-router-dom";

const axios = require('axios').default;

type MyProps = { config: appConfig };
function PasswordPolicy(props: MyProps) {

    function logPasswordPolicyURL() {
        axios.get('/login-redirect/services/passwordPolicyURL');
    }

    return (
        <div className="lk-password-policy password-policy">
            Premier's password policy has changed. <a onClick={logPasswordPolicyURL} href={props.config.passwordPolicyURL} rel="noopener noreferrer=" target="blank">Learn more.</a>
        </div>
    );

}

export default withRouter(PasswordPolicy);