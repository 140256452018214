import React, {useEffect, useRef, useState} from 'react';
import {Link, Redirect, withRouter} from "react-router-dom";
import {AuthException} from '../AuthException';
import AccountLocked from "../AccountLocked";
import postRequest from "../restclient";
import {appConfig} from "../appconfig";
import LoginErrors from "../LoginErrors";
import {authResponse, SigninLookupResult} from "../SignInRestLib";
import OneTimePassword from "./OneTimePassword";
import PasswordPolicy from "./PasswordPolicy";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const axios = require('axios').default;
const fpPromise = FingerprintJS.load();

type myProps = { identifier: string, identifierValid: boolean, config: appConfig, history: string[] };
const interceptKeypressRegex = new RegExp(/^[a-zA-Z0-9+.'@\-_]*$/);

function GetPassword(props: myProps) {
    const [password, setPassword] = useState("");
    const [error, setError] = useState(0);
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const [responseState, setResponseState] = useState<authResponse | null>(null);
    const [isMFA, setMFA] = useState(false);
    const [isFingerPrintExists, setFingerPrintExists] = useState(false);
    const [isNonSSOUserLoggingFirstTime, NonSSOUserLoggingFirstTime] = useState(false);
    const [twoFactorEnabledFlag, setTwoFactorEnabledFlag] = useState(false);
    const [displayPasswordPolicyUrl, setDisplayPasswordPolicyUrl] = useState(false);
    const screenHeight= useRef("");
    const screenWidth= useRef("");
    const visitorId= useRef("");
    const colorDepth= useRef("");
    const timeZone= useRef("");
    const latitude= useRef("");
    const longitude= useRef("");
    const plugins= useRef("");
    const fonts= useRef(new Array());

    useEffect(() => {
        if (props.identifierValid) {
            axios.get('/_signin/doesFingerPrintExists', {params: {identifier: props.identifier}})
                .then(response => {
                    if (response.data === true) {
                        setFingerPrintExists(true);
                    }
                })
        }
    }, []);


    useEffect(() => {

        (async () => {
            // Get the visitor identifier when you need it.
            const fp = await fpPromise
            const result = await fp.get()

            screenHeight.current = String(result.components.screenResolution.value[0]);
            screenWidth.current = String(result.components.screenResolution.value[1]);
            visitorId.current = (String(result.visitorId));
            colorDepth.current = (String(result.components.colorDepth.value));
            timeZone.current = (String(result.components.timezone.value));
            fonts.current = (result.components.fonts.value);

            var installedPlugins = "", i, browserPlugins = result.components.plugins.value;
            for (i = 0; i < browserPlugins.length; i++) {
                installedPlugins += browserPlugins[i].name + ",";
            }
            plugins.current = (installedPlugins.slice(0, -1));

        })()
    }, []);

    useEffect( () => {
        setTimeout(function() {
            const input = document.getElementById("password") as HTMLInputElement;
            if (input) input.focus();
        }, 250);

        window.addEventListener('keydown', focusInput);
        return( () => {window.removeEventListener('keydown', focusInput)})
    }, []);

    useEffect(() => {
        if (props.identifierValid) {
            axios.get('/_signin/is2FAEnabled', {params: {identifier: props.identifier}})
                .then(response => {
                    if (response.data.twoFactorEnabled === true)
                        setTwoFactorEnabledFlag(true);
                })
        }
    }, []);


    useEffect(() => {
        if (!isNonSSOUserLoggingFirstTime && props.identifierValid) {
            const userData = {identifier: props.identifier};
            postRequest('/api/isNonSSOUserLoggingFirstTime', userData)
                .then(response => {
                    const userDetailsResponse = response.data as SigninLookupResult;
                    NonSSOUserLoggingFirstTime(userDetailsResponse.isNonSSOUserLoggingFirstTime);
                    setDisplayPasswordPolicyUrl(props.config.showPasswordPolicyURL && !userDetailsResponse.isUserInternal);
                })
        }
    }, []);

    const focusInput = (e) => {
        let inputChar = String.fromCharCode(e.charCode || e.which);
        if ((!document.activeElement || document.activeElement.tagName === "BODY")) {
            const input = document.getElementById("password") as HTMLInputElement;
            if (input.value != null && interceptKeypressRegex.test(inputChar)) {
                input.focus();
            }
        }
    };
    const resetErrors = (e) => {
        // TODO this key reading is used in a few places
        let code = e.keyCode || e.which;
        let inputChar = String.fromCharCode(e.charCode || e.which);
        if (interceptKeypressRegex.test(inputChar) || code === 8) {
            setError(0);
        }
    }
    const getPassword = (event) => {
        setPassword(event.target.value);
    };

    const getDeviceLocation = () => new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(
        posData => {
            resolve(posData)
            latitude.current = String(posData.coords.latitude);
            longitude.current = String(posData.coords.longitude);
        },
        error => {
            reject(error)
        },
        {
            enableHighAccuracy: true
        }));

    const handleSubmit = (event) => {

        event.preventDefault();
        if (!password) {
            setError(AuthException.AUTH_ERROR_NO_PASSWORD);
            return;
        }
        setSubmitEnabled(false);
        setError(0);

        if (isFingerPrintExists) {
            getDeviceLocation().then(
                data => {
                    sendSubmitData();
                },
                err => {
                    console.log(err);
                    sendSubmitData();
                }
            );
        } else {
            sendSubmitData();
        }
    };

    function sendSubmitData() {

        const deviceProfile = {
            userAgent: navigator.userAgent,
            appName: navigator.appName,
            appCodeName: navigator.appCodeName,
            appVersion: navigator.appVersion,
            product: navigator.product,
            productSub: navigator.productSub,
            vendor: navigator.vendor,
            vendorSub: navigator.vendorSub,
            language: navigator.language,
            screenWidth: screenWidth.current,
            screenHeight: screenHeight.current,
            visitorId: visitorId.current,
            colorDepth: colorDepth.current,
            timeZone: timeZone.current,
            latitude: latitude.current,
            longitude: longitude.current,
            fonts: fonts.current.toString(),
            plugins: plugins.current
        };

        const data = {
            identifier: props.identifier,
            password: password,
            deviceProfile: deviceProfile
        };

        console.log("device-profile ", deviceProfile);

        if (props.identifierValid) {

            postRequest('/_signin/authenticate', data)
                .then(response => {
                    const json = response.data as authResponse;
                    if (json.success) {
                        // console.log("saw success! going to goto");
                        window.location.href = props.config.gotoUrl;
                    } else if (json.callbackJson) {
                        setError(AuthException.TWOFACTOR_REQUIRED);
                        setResponseState(json);
                        setMFA(true);
                        //props.history.push("mfa");
                    } else if (json.error) {
                        /* if (json.error === AuthException.TWOFACTOR_REQUIRED) {
                             setResponseState(json);
                             setError(json.error);
                         }*/
                        if (json.error === AuthException.AUTH_ERROR_TERMS_CONDITIONS) {
                            props.history.push("terms");
                        } else if (json.error === AuthException.AUTH_ERROR_PWD_EXPIRED_GRACE_PERIOD) {
                            props.history.push("changeYourPassword");
                        } else if (json.error === AuthException.AUTH_ERROR_PWD_EXPIRED_GRACE_PERIOD ||
                            json.error === AuthException.AUTH_ERROR_PWD_RESET) {
                            window.location.href = props.config.passwordChangeUrl + "?UserName=" + json.uid;
                        } else if (json.error === AuthException.AUTH_ERROR_PROFILE_REVIEW) {
                            window.location.href = props.config.passwdMgmtProfileReviewURL + "?UserName=" + json.uid;
                        } else if (json.error === AuthException.AUTH_ERROR_ACCOUNT_LOCKED ||
                            json.error === AuthException.AUTH_ERROR_PWD_EXPIRED) {
                            return AccountLocked(); // TODO Fix this
                        } else {
                            setError(json.error);
                            setSubmitEnabled(true);
                        }
                    }
                })
                .catch(reason => {
                    setError(AuthException.AUTH_ERROR_OTHER);
                    setSubmitEnabled(true);
                });

        } else {
            setError(AuthException.AUTH_ERROR_NO_MATCH);
            setSubmitEnabled(true);
        }
    }

    function resetPassword() {
        const userIdentifier = {identifier: props.identifier};
        postRequest('/api/userPasswordReset', userIdentifier)
            .then(response => {
                const passwordResetResponse = response.data as SigninLookupResult;
                if (passwordResetResponse.valid) {
                    props.history.push("resetPassword");
                } else {
                    props.history.push("generalErrorComponent");
                }
            })
    }

    if (isMFA) return <OneTimePassword config={props.config} authResponse={responseState} identifier={props.identifier}
                                       password={password} obtainedLatitude={latitude.current} obtainedLongitude={longitude.current}
                                       history={props.history}/>

    //if (!props.identifier || !props.identifierValid) return <Redirect to="/"/>;
    /* if (error == AuthException.TWOFACTOR_REQUIRED) {
         return <OneTimePassword config={props.config} authResponse={responseState}/>
     }*/
    let submitButtonClasses = "btn btn-block pincai-btn-primary";
    submitButtonClasses += submitEnabled ? "" : " disabled";
    let inputClasses = "form-control";
    if (error !== 0) inputClasses += " is-invalid";

    var buttonLabel;

    if(twoFactorEnabledFlag){
        buttonLabel = submitEnabled ? "Continue" : "...";
    }else{
        buttonLabel = submitEnabled ? "Sign In" : "...";
    }

    return (
        [<div className="form-signin fs-app-container signin signin--sm ">
            <form className={"form-group"} noValidate={true} onSubmit={handleSubmit}>
                <label htmlFor="password" className="title-block"><h4>Signing in as:</h4></label>
                <span className="badge badge-primary">{props.identifier}</span>
                <input aria-hidden={true} className="hidden-field" type="text" name="identifier-field" autoComplete="username" readOnly={true} value={props.identifier}/>
                <input id="password" type="password" name="password" className={inputClasses}  autoComplete="current-password" autoCapitalize="off"
                       onChange={getPassword} onKeyDown={resetErrors}
                       placeholder="Enter your Password" tabIndex={1}/>
                <LoginErrors error={error}/>
                <div className="nav-container">
                    <input type="submit" value={buttonLabel} tabIndex={2} disabled={!submitEnabled} onSubmit={(e) => handleSubmit(e)} className={submitButtonClasses}/>
                </div>

            </form>
            <div className="help-link font-family-sohne">
                { isNonSSOUserLoggingFirstTime ?
                    <a href="#" className="reset password link" onClick={resetPassword}>Reset your one-time password?</a>
                    :
                    <Link tabIndex={3}  to="help">Forgot your password?</Link>
                }
            </div>
        </div>,

        <div>
            {displayPasswordPolicyUrl && <PasswordPolicy config={props.config} /> }
        </div>]

    );

}

export default withRouter(GetPassword);