/**
 * JS version of AuthException.java
 * @type {{AUTH_ERROR_SESSION_NOT_FOUND: number, AUTH_ERROR_PWD_RESET: number, AUTH_ERROR_SSOMEMORY_LOOP: number, AUTH_ERROR_ACCOUNT_LOCKED: number, AUTH_ERROR_PWD_EXPIRED: number, AUTH_ERROR_GENERAL_EXCEPTION: number, AUTH_ERROR_PWD_EXPIRED_GRACE_PERIOD: number, AUTH_ERROR_MFA_UNAUTHORIZED: number, AUTH_ERROR_NO_MATCH: number, AUTH_ERROR_MFA_TIMEOUT: number, AUTH_ERROR_LOGIN_HELP: number, AUTH_ERROR_OTHER: number, AUTH_ERROR_TERMS_CONDITIONS: number,
 * AUTH_ERROR_MFA_MULTIPLE_FAILURE: number}}
 */
export const AuthException = {
 AUTH_ERROR_NO_MATCH: 1,
 AUTH_ERROR_OTHER : 2,
 AUTH_ERROR_PWD_EXPIRED : 3,
 AUTH_ERROR_PWD_EXPIRED_GRACE_PERIOD : 4,
 AUTH_ERROR_ACCOUNT_LOCKED : 5,
 AUTH_ERROR_PWD_RESET : 6,
 AUTH_ERROR_GENERAL_EXCEPTION : 7,
 AUTH_ERROR_LOGIN_HELP : 8,
 AUTH_ERROR_SESSION_NOT_FOUND : 9,
 AUTH_ERROR_MFA_UNAUTHORIZED : 10,
 AUTH_ERROR_MFA_TIMEOUT : 11,
 AUTH_ERROR_TERMS_CONDITIONS : 12,
 AUTH_ERROR_SSOMEMORY_LOOP : 13,
 AUTH_ERROR_ACCOUNT_DISABLED: 14,
 AUTH_ERROR_ACCOUNT_NOT_FOUND: 15,
 AUTH_ERROR_MFA_NOT_REGISTERED: 16,
 AUTH_ERROR_NO_PASSWORD: 17,
 AUTH_ERROR_NO_IDENTIFIER: 18,
 BROWSER_WARNING: 19,
 BROWSER_BLOCK: 20,
 AUTH_ERROR_MULTIPLE_ACCOUNTS: 21,
 TWOFACTOR_REQUIRED: 22,
 AUTH_ERROR_MFA_MULTIPLE_FAILURE: 23,
 AUTH_ERROR_PROFILE_REVIEW: 24,
 UNSUPPORTED_MFA_TYPE: 26,
 BACKUP_CODE_TOO_MANY_ATTEMPTS: 27,
 SESSION_EXPIRED: 28,
 UNAUTHORIZED:29,
 DENIED_LOCATION: 30,
 AUTH_ERROR_PWD_WRONG_GRACE_PERIOD: 31
};