// import {SigninLookupResult} from "./SignInRestLib";
import {AuthException} from './AuthException';
import React from 'react';

type MyProps = { error: number}
export default function LoginErrors({error}: MyProps) {
    const accountDisabled = error === AuthException.AUTH_ERROR_ACCOUNT_DISABLED;
    const accountInvalid = error === AuthException.AUTH_ERROR_ACCOUNT_NOT_FOUND;
    const unexpectedError = error === AuthException.AUTH_ERROR_OTHER;
    const badPassword = error === AuthException.AUTH_ERROR_NO_MATCH;
    const expiredGracePassword = error === AuthException.AUTH_ERROR_PWD_WRONG_GRACE_PERIOD;

    let text;
    if (accountInvalid) text = "Your account was not recognized.";
    if (accountDisabled) text = "Your account is disabled.  Please call the Solution Center.";
    if (error === AuthException.AUTH_ERROR_MULTIPLE_ACCOUNTS) text = "Multiple accounts found! Please try your Premier User ID."
    if (unexpectedError) text = "An unexpected error occurred, please try again.";
    if (badPassword) text = "Wrong credentials! Please try again.";
    if (expiredGracePassword) text = "Password has expired. Please use \"Forgot your password?\" link below.";
    if (error === AuthException.AUTH_ERROR_NO_PASSWORD) text = "Please enter a password."
    if (error === AuthException.AUTH_ERROR_MFA_UNAUTHORIZED) text = "Wrong code.  Please try again.";
    if (error === AuthException.AUTH_ERROR_NO_IDENTIFIER) text = "Please enter your User ID or E-Mail.";
    if (error === AuthException.AUTH_ERROR_MFA_TIMEOUT) text = "Your one time password has expired.  Please request a new code."
    if(error === AuthException.AUTH_ERROR_MFA_MULTIPLE_FAILURE) text = "Too many failed attempts. Please request a new code."
    if(error === AuthException.UNSUPPORTED_MFA_TYPE) text = "Unsupported MFA type."
    if(error === AuthException.BACKUP_CODE_TOO_MANY_ATTEMPTS) text = "Too many attempts, your account has been locked. Please call 877.777.1552"
    if(error === AuthException.SESSION_EXPIRED) text = "Session expired, please signin again."
    if(error === AuthException.DENIED_LOCATION) text = "For Premier to remember your device, please update browser location settings."

    if (text) {
        return (
            <div className="display-mfa-error">{text}</div>
        );
    } else {
        return null;
    }
}