import React, {useEffect, useRef, useState} from 'react';
import LoginErrors from "../LoginErrors";
import {appConfig} from "../appconfig";
import {authResponse} from "../SignInRestLib";
import {AuthException} from "../AuthException";
import postRequest from "../restclient";
import ResendOTP from "./ResendOTP";
import TryAnotherWay from "./TryAnotherWay";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const axios = require('axios').default;

type MyProps = { config: appConfig, authResponse: authResponse, identifier: string, password: string, resendPageCallBackJson?: string, fromLoginAdvice? : boolean, obtainedLatitude?: string, obtainedLongitude?: string, history?: string[]};

export default function OneTimePassword(props: MyProps) {
    const [responseState, setResponseState] = useState<authResponse | null>(null);
    const [callbackJson, setCallbackJson] = useState(null);
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [code, setCode] = useState<string>("");
    const [error, setError] = useState(0);
    const [startedMFA, setStartedMFA] = useState(false);
    const [requestHOTP, setRequestHOTP] = useState(false);
    const [identity, setIdentity] = useState(props.identifier);
    const [pass, setPass] = useState(props.password);
    const [onOTPLaunch, setOnOTPLaunch] = useState(false);
    const [onTryAnotherWayLaunch, setOnTryAnotherWay] = useState(false);
    const [checked, setChecked] = useState(false);
    const [sixDigitCode, setSixDigitCode] = useState(false);
    const screenHeight= useRef("");
    const screenWidth= useRef("");
    const visitorId= useRef("");
    const colorDepth= useRef("");
    const timeZone= useRef("");
    const latitude= useRef("");
    const longitude= useRef("");
    const plugins= useRef("");
    const fonts= useRef(new Array());
    const fpPromise = FingerprintJS.load();
    const [deviceProfile, setDeviceProfile] = useState({});
    const [checkBoxError, setCheckBoxError] = useState(false);
    const [showTryAnotherWayLinkOnScreen, setShowTryAnotherWayLinkOnScreen] = useState(false);

    useEffect( () => {
        var callbackJsonForTryAnotherWay = JSON.parse(props.authResponse.callbackJson);
        for (let index in callbackJsonForTryAnotherWay["callbacks"]) {
            if (callbackJsonForTryAnotherWay["callbacks"][index].type == "ConfirmationCallback") {
                var obj = callbackJson["callbacks"][index].output[2];
                if (obj.name == "options"){
                    var finalValue = obj.value.length;
                    if(finalValue > 2)
                        setShowTryAnotherWayLinkOnScreen(true);
                }
                break;
            }
        }
    }, []);

    useEffect(() => {

        (async () => {
            // Get the visitor identifier when you need it.
            const fp = await fpPromise
            const result = await fp.get()

            screenHeight.current = String(result.components.screenResolution.value[0]);
            screenWidth.current = String(result.components.screenResolution.value[1]);
            visitorId.current = (String(result.visitorId));
            colorDepth.current = (String(result.components.colorDepth.value));
            timeZone.current = (String(result.components.timezone.value));
            fonts.current = (result.components.fonts.value);

            var installedPlugins = "", i, browserPlugins = result.components.plugins.value;
            for (i = 0; i < browserPlugins.length; i++) {
                installedPlugins += browserPlugins[i].name + ",";
            }
            plugins.current = (installedPlugins.slice(0, -1));

        })()
    }, []);


    const getDeviceLocation = () => new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition(
        posData => {
            resolve(posData)
            latitude.current = String(posData.coords.latitude);
            longitude.current = String(posData.coords.longitude);
        },
        error => {
            reject(error)
        },
        {
            enableHighAccuracy: true
        }));

    function handleCheckboxSubmit (value) {
        setSubmitEnabled(value);
    }

    const handleBrowserCheckBox = () => {

        if (sixDigitCode) {
            handleCheckboxSubmit(!submitEnabled);
        }

        setChecked(!checked);
        if (!checked) {
            if (!props.obtainedLatitude || !props.obtainedLongitude) {
                if (!(latitude.current && longitude.current)) {
                    console.log("getting location ");
                    getDeviceLocation().then(
                        data => {
                            buildDeviceProfile();
                        },
                        err => {
                            console.log("err " + err);
                            if (err.message === 'User denied Geolocation') {
                                setCheckBoxError(true);
                                setChecked(false);
                            }
                            if (sixDigitCode) {
                                handleCheckboxSubmit(true);
                            }
                        }
                    );
                } else {
                    buildDeviceProfile();
                }
            } else {
                latitude.current = props.obtainedLatitude;
                longitude.current = props.obtainedLongitude;
                buildDeviceProfile();
            }
        } else {
            if (sixDigitCode) {
                handleCheckboxSubmit(true);
            }
        }
    };

    const buildDeviceProfile = () => {

        let device_profile = {
            userAgent: navigator.userAgent,
            appName: navigator.appName,
            appCodeName: navigator.appCodeName,
            appVersion: navigator.appVersion,
            product: navigator.product,
            productSub: navigator.productSub,
            vendor: navigator.vendor,
            vendorSub: navigator.vendorSub,
            language: navigator.language,
            screenWidth: screenWidth.current,
            screenHeight: screenHeight.current,
            visitorId: visitorId.current,
            colorDepth: colorDepth.current,
            timeZone: timeZone.current,
            latitude: latitude.current,
            longitude: longitude.current,
            fonts: fonts.current.toString(),
            plugins: plugins.current
        }
        console.log("DeviceProfile lat " + latitude.current);
        setDeviceProfile(device_profile);

        if (sixDigitCode) {
            handleCheckboxSubmit(true);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setError(0);

        if ((checked === true) && (latitude.current === "" || longitude.current === "")) {
            setCheckBoxError(true);
            setChecked(false);
        } else {

            for (let index in callbackJson["callbacks"]) {
                if (callbackJson["callbacks"][index].type == "PasswordCallback") {
                    callbackJson["callbacks"][index].input[0].value = code;
                    break;
                }
            }

            const mfaRequest = {
                mfaCallBackJSON: JSON.stringify(callbackJson),
                deviceProfile: deviceProfile
            };

            axios.post("/login-redirect/services/mfa", mfaRequest,
                {
                    params: {
                        goto: props.config.gotoUrl,
                        identifier: props.identifier,
                        fromReAuth: props.fromLoginAdvice,
                        trustBrowser: checked
                    }
                })
                .then(response => {
                    const json = response.data as authResponse;
                    if (json.success) {
                        if (!props.fromLoginAdvice) {
                            window.location.replace(json.url);
                        } else {
                            if (json.url.toLowerCase().indexOf("?_txid") !== -1) {
                                window.location.replace(json.url);
                            } else {
                                setError(AuthException.BACKUP_CODE_TOO_MANY_ATTEMPTS);
                            }
                        }
                    } else {
                        let newCallbackJson = JSON.parse(json.callbackJson);
                        setCallbackJson(newCallbackJson);
                        if(json.error === AuthException.AUTH_ERROR_TERMS_CONDITIONS){
                            props.history.push("terms");
                        } else if (json.userAuthStage === 'selectMfaMethod' || json.error === AuthException.AUTH_ERROR_MFA_MULTIPLE_FAILURE) {
                            setError(AuthException.BACKUP_CODE_TOO_MANY_ATTEMPTS);
                        } else if (json.error === AuthException.AUTH_ERROR_PWD_EXPIRED_GRACE_PERIOD ||
                            json.error === AuthException.AUTH_ERROR_PWD_RESET) {
                            const Message = encodeURI(props.config.passwordChangeUrl + "?UserName=" + json.uid);
                            window.location.href = decodeURI(Message);
                        } else if (json.error !== AuthException.AUTH_ERROR_MFA_TIMEOUT && json.error !== AuthException.AUTH_ERROR_MFA_MULTIPLE_FAILURE) {
                            setError(AuthException.AUTH_ERROR_MFA_UNAUTHORIZED);
                        } else {
                            setError(json.error);
                        }
                    }
                });
        }
    }

    const startMFA = () => {
        setStartedMFA(true);
        setCode("");
        setSubmitEnabled(false);
        setError(0);
        const data = {
            identifier: identity,
            password: pass
        };

        if (identity === "composite_advice") {
            axios.get('/login-redirect/services/startMFA', callbackJson)
                .then(response => {
                        const json = response.data as authResponse;
                        if (json.callbackJson) {
                            let callbackJson = JSON.parse(json.callbackJson);
                            setCallbackJson(callbackJson);
                            setResponseState(json);
                            /*setServerMessage(json.message)*/
                            if (callbackJson.tokenId) {
                                window.location.replace(props.config.gotoUrl);
                            }
                        } else {
                            setError(json.error);
                        }
                    }
                );
        } else {
            postRequest('/_signin/authenticate', data)
                .then(response => {
                        const json = response.data as authResponse;
                        if (json.callbackJson) {
                            let callbackJson = JSON.parse(json.callbackJson);
                            setCallbackJson(callbackJson);
                            setResponseState(json);
                            /*setServerMessage(json.message)*/
                            if (callbackJson.tokenId) {
                                window.location.replace(props.config.gotoUrl);
                            }
                        } else {
                            setError(json.error);
                        }
                    }
                )
        }
        ;

    }
    const onCodeUpdate = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setCode(e.target.value);
        }
        if(e.target.value.length === 6 ){
            setSubmitEnabled(true);
            setSixDigitCode(true);
        }
        else {
            setSubmitEnabled(false);
            setSixDigitCode(false);
        }
        //setSubmitEnabled(e.target.value.length === 6 ? true : false);
    }
    const requestNewHOTP = (e) => {
        e.preventDefault();
        setCode("");
        setSubmitEnabled(false);
        setRequestHOTP(true);
        setStartedMFA(false);
        setResponseState(props.authResponse);
    }

    function tryAnotherWay() {
        setOnTryAnotherWay(true);
    }

    if (onTryAnotherWayLaunch) {
        return (<TryAnotherWay config={props.config} authResponse={responseState} identifier={props.identifier} password={props.password} fromLoginAdvice={props.fromLoginAdvice} history={props.history}/>);
    }

    function resendOTP() {
        setOnOTPLaunch(true);
    }

    if (onOTPLaunch) {
        return (<ResendOTP config={props.config} authResponse={responseState} identifier={props.identifier} password={props.password} history={props.history}/>);
    }

    function onChange(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({value: e.target.value})
        }
    }

    const inputStyle = error ? "is-invalid" : "";
    let nextButtonClasses = "btn btn-block pincai-btn-primary";
    nextButtonClasses += submitEnabled ? "" : " disabled";
    // const buttonLabel = submitEnabled ? "Verify" : "enter code";
    const buttonLabel = "Submit";
    if (props.authResponse && !startedMFA && !requestHOTP) {
        setResponseState(props.authResponse);
        if (props.resendPageCallBackJson) {
            setCallbackJson(props.resendPageCallBackJson);
        } else {
            let callbackJson = JSON.parse(props.authResponse.callbackJson);
            setCallbackJson(callbackJson);
        }
        setStartedMFA(true);
    } else {
        if (!startedMFA && !props.resendPageCallBackJson) {
            startMFA()
        }
        ;
    }

    if (error === AuthException.AUTH_ERROR_ACCOUNT_DISABLED || error === AuthException.AUTH_ERROR_SESSION_NOT_FOUND) window.location.replace("/signin");
    if (error === AuthException.AUTH_ERROR_MFA_NOT_REGISTERED) {
        return <div>
            <h4 className="alert-heading">2-Step Verification Needed</h4>
            <p>The page that you are trying to reach requires text message verification, but you are not registered.</p>
            {props.config.isRemoteUserInternal ?
                <p style={{marginBottom: '0px'}}>Call the Service Desk to register at <strong><a
                    className="alert-danger" href="tel:+17048164357">704.816.4357</a></strong> or toll free
                    at <strong><a className="alert-danger" href="tel:+18002182266">800.218.2266</a></strong>.</p>
                : <p style={{marginBottom: '0px'}}>Please contact the Premier Solution Center to register.</p>
            }
        </div>
    }

    return (
        error === AuthException.UNAUTHORIZED ?
            <div className="alert alert-danger" id="errMsg">
                <p className="zeroBottom"><strong>Whoops! </strong>Looks like you're having trouble.  You've used all your retry attempts and so we've locked your account to keep it safe.  Please call the Premier Solution Center at 877.777.1552.</p>
            </div>
            :
            <div className={"form-signin fs-app-container signin signin--sm"}>
                <h4>2-Step Verification</h4>
                {responseState === null && "Sending verification code..."}
                {/*<div>{serverMessage}</div><br/>*/}

                <form className={"form-group"} noValidate={true} autoComplete="off">
                    <label htmlFor="username"></label>
                    {responseState !== null && <div>
                        <h4 className={"twofactor-text"}>A text message was sent to your device.</h4><p></p>
                        <h4 className={"twofactor-text"}>Please enter the code in the field below.</h4><p></p>
                        <input type="otp" id="code" name="code" value={code} width={6}
                               pattern="[0-9]*" inputMode="numeric" autoComplete="one-time-code"
                               autoFocus onChange={onCodeUpdate} tabIndex={1} required={true}
                               className={"form-control " + inputStyle}
                               placeholder="Enter code" title="your verification code"/>
                        <LoginErrors error={error}/>
                        <div className="nav-container">
                            {error !== AuthException.AUTH_ERROR_MFA_TIMEOUT && error !== AuthException.BACKUP_CODE_TOO_MANY_ATTEMPTS ?
                                <input type="submit" value={buttonLabel} tabIndex={2} disabled={!submitEnabled}
                                       className={nextButtonClasses}
                                       onClick={(e) => handleSubmit(e)}/>
                                :
                                <h4></h4>
                                /*<input type="submit" value="Request new code" className={nextButtonClasses} id="mfa-button"
                                       name="mfa"
                                       onClick={(e) => requestNewHOTP(e)}/>*/
                            }
                        </div>
                        <p></p>
                        <div>
                            <label style = {{fontSize: 13}}>
                                <input
                                    style = {{width: 18,  height:18, verticalAlign: -4}}
                                    type="checkbox"
                                    checked={checked}
                                    disabled={checkBoxError}
                                    onChange={handleBrowserCheckBox}
                                />      Don't ask me again on this browser for 24 hours?
                            </label>
                        </div>
                        {checkBoxError && <div className="display-mfa-error">For Premier to remember your browser, please update browser location settings.</div>}
                        <p></p>
                        {error !== AuthException.AUTH_ERROR_MFA_TIMEOUT && error !== AuthException.BACKUP_CODE_TOO_MANY_ATTEMPTS ?
                            <h4 className={"twofactor-text-links"}>Didn't get a code? <a
                                className="twofactor-text-links alert-link pull-xs-left zeroBottom extraTopMargin" href="#"
                                onClick={resendOTP}>Resend</a></h4>
                            :
                            <h4></h4>
                        }
                        {error !== AuthException.AUTH_ERROR_MFA_TIMEOUT && error !== AuthException.BACKUP_CODE_TOO_MANY_ATTEMPTS && showTryAnotherWayLinkOnScreen?
                            <h4><a className="twofactor-text-links alert-link pull-xs-left zeroBottom extraTopMargin" href="#"onClick={tryAnotherWay}>Try
                                another way?</a></h4>
                            :
                            <h4></h4>
                        }
                    </div>
                    }
                </form>
            </div>
    );

}